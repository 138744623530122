import React from 'react';
import parse from 'html-react-parser';

import loadComponents from '../Loadable';

const Team = loadComponents('team');
const Faq = loadComponents('faq');
const Gallery = loadComponents('lightbox-gallery');
const Testimonials = loadComponents('testimonials');
const Cta = loadComponents('cta');
const ImageBlock = loadComponents('subpage-image');
const ImageText = loadComponents('subpage-image-text');

const Additional = ({ data }) => {
  return data.map((section, index) => {
    const key = section.fieldGroupName + index;
    const checkCondition = id => {
      return section.fieldGroupName.includes(id);
    };
    return (
      <section className="additional" key={key}>
        {section.fieldGroupName &&
          (() => {
            switch (true) {
              case checkCondition('MeetTheTeam'):
                return (
                  <Team title={section.title} data={section.teamMembers} />
                );
              case checkCondition('FrequentlyAskedQuestions'):
                return <Faq title={section.title} data={section.faq} />;
              case checkCondition('Gallery'):
                return (
                  <Gallery title={section.title} images={section.images} />
                );
              case checkCondition('Testimonials'):
                return (
                  <Testimonials
                    title={section.title}
                    data={section.testimonial}
                  />
                );
              case checkCondition('CallToAction'):
                return <Cta data={section} />;
              case checkCondition('ImagetextBlock'):
                return <ImageText data={section} />;
              case checkCondition('FullWidthImage'):
                return <ImageBlock data={section} />;
              case checkCondition('AdditionalText'):
                return (
                  <div
                    style={{
                      marginTop: `3.75rem`,
                    }}
                  >
                    {parse(section.content)}
                  </div>
                );
              default:
                return false;
            }
          })()}
      </section>
    );
  });
};

export default Additional;
